import React from "react";
import Layout from '../../../components/Layout'
import { FaAngleRight } from "@react-icons/all-files/fa/FaAngleRight";

import { Link,graphql,navigate } from "gatsby";
import Video from "../../../components/Video";
import BackgroundImage from "gatsby-background-image";
import Accordion from "../../../components/Accordion";

// import Markdown from "react-markdown";
// import rehypeRaw from 'rehype-raw'


// const markdown = `A paragraph with *emphasis* and **strong importance**.`


const ComponentName = ({ data }) => {
  const daa =data.strapiProducts
  const ProductsList = data.allStrapiProducts.nodes
  const servicesList= data.allStrapiServices.nodes
  return (
    <Layout title={data.strapiProducts.title}>
      <div className="services">
      <BackgroundImage
        backgroundColor={`#0080FF`}
        fluid={daa.image.localFile.childImageSharp.fluid}
         className="hero">
         <div className="overlay">
           <h1>{daa.hindiTitle}</h1>
          </div>
          </BackgroundImage>

        <div className="ssbody wrapper_max">

          <div className="naviBody">
          <div className="navigation">
          <h3>हमारे उत्पाद</h3>
            <div className="list">
              {ProductsList.map((v, k) => (
                <Link activeClassName="active" to={"/products/hindi/"+v.slug}>
                  <div key={k}>
                    <p>{v.hindiTitle}</p>
                    <FaAngleRight />
                  </div>
                </Link>
              ))}
            </div>
          </div>

          <div className="navigation">
          <h3>हमारी सेवाएँ</h3>      
            <div className="list list2">
              {servicesList.map((v, k) => (
                <Link activeClassName="active" to={"/services/hindi/"+v.slug}>
                  <div key={k}>
                    <p>{v.titleHindi}</p>
                    <FaAngleRight />
                  </div>
                </Link>
              ))}
            </div>
          </div>
          </div>

          <div className="content">
          {/* <div className="hindiLink" style={{cursor:'pointer'}} onClick={()=>{  navigate('/products/'+daa.slug)}} data-nosnippet>Read in English</div> */}
            <h2>{daa.hindiTitle}</h2>
            {/* <p>
                {daa.body}
            </p> */}
            {/* <Markdown className='page-body' rehypePlugins={[rehypeRaw]} children={daa.body} /> */}
            <div className='page-body' dangerouslySetInnerHTML={{__html: daa.bodyHindi}} />
                          <Accordion data={daa.hindiSection}/>

          </div>
        </div>
      </div>
      {daa.youtubeLink!=null&&daa.youtubeLink!="null"&&daa.youtubeLink!=""&&<Video url={daa.youtubeLink}/>}
    </Layout>
  );
};

export const query = graphql`
  query productsQueryAndProductsQuery($slug: String!) {
    strapiProducts(slug: { eq: $slug }) {
      id
      slug
      title
      body
      youtubeLink
      bodyHindi
      hindiTitle
       hindiSection{
        body
        title
      }
      image {
        localFile {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1900) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    allStrapiProducts {
      nodes {
        slug
        hindiTitle
      }
    }
    allStrapiServices {
      nodes {
        slug
        titleHindi
      }
    }
  }
`;

export default ComponentName;
